const isLargeScreen = () => {
  return (
    Math.max(
      document.documentElement["clientWidth"],
      document.body["scrollWidth"],
      document.documentElement["scrollWidth"],
      document.body["offsetWidth"],
      document.documentElement["offsetWidth"]
    ) > 640
  );
};

export default isLargeScreen;
