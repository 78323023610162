import React from "react";
import { FaExternalLinkSquareAlt } from "react-icons/fa";

export default function ProjectCard({ image, name, description, url }) {
  return (
    <div className="card p-0 rounded-md sm:px-10 sm:py-6 md:p-0 lg:h-auto xl:h-[85rem]">
      <div className="h-max">
        <img
          className="w-full rounded-sm mb-4 sm:rounded-md"
          src={image}
          alt={name}
        />
      </div>
      <div className="lg:h-64 px-5 pb-5 sm:px-10 md:px-12 lg:p-5 lg:m-0">
        <h3 className="content-heading md:my-3 my-1 tracking-wider lg:my-4 md:mt-5 lg:mt-0">
          {name}
        </h3>
        <div className="lg:h-20 my-2">
          <p className="content-text md:py-2">{description}</p>
        </div>
        <a
          href={"https://" + url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-wrapper"
        >
          <button className="btn-primary mt-3 lg:mt-0">
            <div className="flex align-center justify-center">
              <p className="content-text">Visit </p>
              <div className="relative m-auto pt-0.5">
                <FaExternalLinkSquareAlt className="ml-1" />{" "}
              </div>
            </div>
          </button>
        </a>
      </div>
    </div>
  );
}
