class Wave {
  constructor(
    p5,
    plotFunction,
    amplitude,
    phase,
    period,
    phaseIncrement = 0,
    offset = 0,
    color = [0, 0, 0],
    fill = false
  ) {
    this.fill = fill;
    this.color = color;
    this.amplitude = amplitude;
    this.phase = phase;
    this.period = period;
    this.offset = offset;
    this.p5 = p5;
    this.plotFunction = plotFunction;
    this.phaseIncrement = phaseIncrement;
  }

  calculate(x) {
    return (
      this.amplitude *
        this.plotFunction(this.phase + (this.p5.TWO_PI * x) / this.period) +
      this.offset
    );
  }
  draw() {
    const p5 = this.p5;
    p5.fill(0);
    p5.stroke(...this.color);
    p5.beginShape();
    p5.strokeWeight(2);
    for (let x = 0; x <= p5.width; x += 10) {
      const y = this.calculate(x);
      p5.vertex(x, y);
    }
    p5.noFill();
    p5.endShape();
  }
  update() {
    this.draw();
    this.phase += this.phaseIncrement;
  }

  static len = 250;
  static position = 50;
  static waveNum = 1;
  static factor = 1;
  static drawAdditive(
    waves,
    subsetSize,
    colors = null,
    offset = [0, 0],
    animate = false
  ) {
    // console.log("drawing wave", this.waveNum, waves.length);
    const p5 = waves[0].p5;
    if (!animate) {
      this.factor = 0;
    }
    colors = colors === null ? [[0, 0, 0]] : colors;
    const draw = (waves, color) => {
      p5.colorMode(p5.HSB);
      p5.stroke(...color);
      p5.noFill();
      p5.strokeWeight(8);

      p5.beginShape();
      for (let x = 0; x <= p5.width + 100; x += 10) {
        let y = 0;
        waves.forEach((wave) => {
          y += wave.calculate(x);
        });
        y /= waves.length;
        const translatedX = x + offset[0];
        const translatedY = p5.map(
          this.factor,
          0,
          1,
          y + offset[1] + (this.waveNum - 1) * (this.waveNum * 8 + 28),
          y + 501
        );
        if (this.factor > 0) {
          this.factor -= p5.map(this.factor, 0, 0.65, 0.00000009, 0.000042);
        }

        p5.vertex(translatedX, translatedY);
        p5.strokeWeight(11);
        //p5.stroke(...[0, 0, 0].map(() => p5.random(0, 255)));
        p5.line(translatedX, p5.height + 200, translatedX, translatedY + 2.5);
        p5.strokeWeight(8);
      }
      //   this.position += 1;
      //   if (this.position > p5.width) {
      //     this.position = 0;
      //   }
      p5.endShape();
    };

    if (waves.length > subsetSize) {
      const waveSet = waves.slice(0, subsetSize);
      draw(waveSet, colors[0], this.waveNum);
      waveSet.forEach((wave) => (wave.phase += wave.phaseIncrement));
      this.waveNum++;
      this.drawAdditive(
        waves.slice(subsetSize),
        subsetSize,
        colors.slice(1),
        offset,
        animate
      );
    } else {
      draw(waves, colors[0]);
      waves.forEach((wave) => (wave.phase += wave.phaseIncrement));
      this.waveNum = 1;
    }
  }
}

let waves = [];

function sketch(p5) {
  const numWaves = 12;
  const waveSetSize = 2;
  const waveOffset = [0, 20];
  const hueStepSize = 0.15;
  const colors = [];
  const maxHue = 280;
  const minHue = 200;
  let hue = 270;

  let hueIncreasing = false;
  let bgColor = [255];
  let currentYPosition = 100;
  const targetYPosition = -200;
  p5.setup = () => {
    p5.createCanvas(600, 400);

    const numColors = numWaves / waveSetSize;
    for (let i = 0; i < numColors; i++) {
      const b = 100 - p5.map(i, 0, numColors, 0, 100);
      // HSB
      // 0 0-100 0-100
      colors.push([0, 360, b]);
    }
    for (let i = 1; i <= numWaves; i++) {
      waves.push(
        new Wave(
          p5, // render protocol
          (a) => p5.sin(a), // plot function
          p5.map(i, 1, numWaves, p5.random(5, 7), p5.random(25, 30)), // amplitude
          i * 15, // phase
          p5.map(i, 1, numWaves, 100, 700), // period
          p5.map(
            i,
            1,
            numWaves,
            p5.random(0.005, 0.0075),
            p5.random(0.014, 0.017)
          ), // phase increment
          0,
          [0, 0, 0] // color
        )
      );
      // waves.push(
      //   new Wave(
      //     p5,
      //     (a) => p5.sin(a),
      //     p5.random(10, 70),
      //     i,
      //     p5.random(100, 600),
      //     0.005
      //   )
      // );
    }
  };
  p5.draw = () => {
    p5.rotate(0.3);
    p5.translate(0, -200);
    p5.background(...bgColor);
    if (currentYPosition > targetYPosition) {
      currentYPosition--;
    }
    // p5.strokeWeight(5);
    // p5.line(0, 0, p5.width, p5.height);

    // Draw each wave:
    // waves.forEach((wave) => wave.update());

    // Draw Additive wave:

    Wave.drawAdditive(
      waves,
      waveSetSize,
      colors.map((color) => [hue, ...color.slice(1)]),
      waveOffset,
      true
    );
    if (hue > minHue && hue < maxHue) {
      hue = hueIncreasing ? hue + hueStepSize : hue - hueStepSize;
    } else if (hue >= maxHue) {
      hueIncreasing = false;
      hue -= hueStepSize;
    } else {
      hueIncreasing = true;
      hue += hueStepSize;
    }
  };
}

export default sketch;
