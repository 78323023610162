import React from "react";
import {
  AiFillLinkedin,
  AiFillGithub,
  AiFillMediumSquare,
} from "react-icons/ai";

const Social = () => {
  return (
    <div
      style={{ marginBottom: "30vh" }}
      className="flex mt-36 align-center justify-center w-full h-min"
    >
      <div className="glass center-clipped py-2 px-11 w-max flex justify-center align-center flex-gap">
        <a
          href="https://www.linkedin.com/in/hammaad-memon-3a007b211/"
          target="_blank"
          rel="noopener noreferrer"
          className="alt-dynamic-wrapper w-min p-0.5 h-min mx-1 rounded-full"
        >
          <AiFillLinkedin
            className="alt-dynamic md:w-10 md:h-10"
            color="white"
            size={38}
          />
        </a>
        <a
          href="https://github.com/Hammaad-M"
          target="_blank"
          rel="noopener noreferrer"
          className="alt-dynamic-wrapper w-min p-0.5 h-min mx-1 rounded-full"
        >
          <AiFillGithub
            className="md:w-10 md:h-10 alt-dynamic "
            color="white"
            size={38}
          />
        </a>
        <a
          href="https://hammaad-m.medium.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="alt-dynamic-wrapper w-min p-0.5 h-min mx-1 rounded-full"
        >
          <AiFillMediumSquare
            className="md:w-10 md:h-10 alt-dynamic "
            color="white"
            size={38}
          />
        </a>
      </div>
    </div>
  );
};

export default Social;
