import React from "react";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";
import Projects from "./components/Projects";
import Social from "./components/Social";
import Background from "./components/Background";
import Certfications from "./components/Certfications";
import Contact from "./components/Contact";
import Skills from "./components/Skills";

function App() {
  const productionMode = true; // toggle for production
  const productionURL = "www.hammaadmemon.com"; // domain name
  const homeURL = productionMode ? productionURL : "https://localhost:3000";
  return (
    <>
      <Background />
      {/* <div
            className="fixed w-full h-screen bg-black"
            style={{ zIndex: -100 }}
          ></div> */}
      <NavBar homeURL={homeURL} />
      <Hero />
      <Social />
      {/* <About /> */}
      <Projects />
      <Skills />
      <Certfications />
      <Contact />
    </>
  );
}

export default App;
