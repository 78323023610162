import React from "react";
import ScrollTarget from "./ScrollTarget";
import ProjectCard from "./ProjectCard";

const projectCategories = [
  {
    name: "Featured",
    items: [
      // {
      //   name: "Medicare Hospital Website",
      //   description: "Medicare Hospital's streamlined brand page.",
      //   image: "assets/medicare-hospital.jpeg",
      //   url: "medicare-hospital.netlify.app",
      // },
      {
        name: "Social Circle",
        description:
          "Enhanced contact book with live chatting and social networking features.",
        image: "assets/socialcircle.jpg",
        url: "socialcirclebeta.netlify.app",
      },
      {
        name: "Custom Calligraphy Ecommerce",
        description:
          "Interactive ecommerce website for creating custom calligraphy pieces.",
        image: "assets/customcalligraphy.jpg",
        url: "customcalligraphy.netlify.app",
      },
      // {
      //   name: "ECL Awards Website",
      //   description:
      //     "MCNA's Eastside Leadership Award's event information and nomination mechanism.",
      //   image: "assets/ecl-awards.jpg",
      //   url: "eclawards.netlify.app",
      // },
      {
        name: "Fasting Countdown",
        description:
          "Prayer and Fasting time calculator used by muslims around the world.",
        image: "assets/ramadan-timer.jpeg",
        url: "www.ramadantimer.com",
      },

      // {
      //   name: "Camera Systems Engineer Portfolio",
      //   description: "Sleek and interactive personal portfolio.",
      //   image: "assets/am-portfolio.jpg",
      //   url: "memon.netlify.app",
      // },
      {
        name: "Puyallup Masjid Website",
        description: "Puyallup Masjid's informational website.",
        image: "assets/puyallup-masjid.jpg",
        url: "puyallupmasjid.netlify.app",
      },
    ],
  },
  {
    name: "AI & Machine Learning",
    items: [
      {
        name: "Music Extractor",
        description: "Streams YouTube videos with removed background music.",
        image: "assets/music-extractor.jpeg",
        url: "www.switchoffmusic.com",
      },
      {
        name: "Centerstage Webcam",
        description: "Tracks and focuses camera on faces from webcam feed.",
        image: "assets/centerstage.jpg",
        url: "centerstage.netlify.app/",
      },
    ],
  },
  {
    name: "Web Tools & Simulators",
    items: [
      {
        name: "Video Zoom",
        description: "Zoom into any region of a video and save snapshots.",
        image: "assets/videozoom.jpeg",
        url: "videozoom.netlify.app",
      },
      {
        name: "Camera FOV Simulator",
        description: "Simulate fields of view for any image in the browser.",
        image: "assets/fov-sim.jpeg",
        url: "fov-simulator.netlify.app",
      },
      {
        name: "Flash Projector",
        description:
          "Creates and helps analyze virtual projection of Flash LEDs.",
        image: "assets/light-projector.jpeg",
        url: "lightprojectorsim.netlify.app",
      },
      {
        name: "Image Region Normalizer",
        description: "Normalizes any image based on custom normalization grid.",
        image: "assets/image-normalizer.jpg",
        url: "imagenormailzer.netlify.app/",
      },
    ],
  },
];

const Projects = () => {
  return (
    <section className="projects-container section">
      <ScrollTarget name={"projects"}></ScrollTarget>
      <h1 className="section-heading">Projects</h1>
      <p className="section-desc mb- md:mb-3 lg:mb-4 xl:mb-5">
        Web solutions used and trusted around the world.
      </p>
      {projectCategories.map((category, i) => (
        <>
          <p className="section-subheading tracking-wider lg:-mb-8 mt-6">
            {category.name}
          </p>

          <div className="card-container md:grid md:grid-flow-row md:grid-cols-2 md:gap-x-4 md:gap-y-2">
            {category.items.map((project, i) => (
              <ProjectCard key={i} {...project} />
            ))}
          </div>
        </>
      ))}
    </section>
  );
};

export default Projects;
