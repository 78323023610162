import React from "react";
import { Element } from "react-scroll";

const ScrollTarget = ({ name }) => {
  return (
    <div className="lg:-top-10 bg-transparent absolute -top-16">
      <Element name={name}></Element>
    </div>
  );
};

export default ScrollTarget;
