import React from "react";

const Hero = () => {
  return (
    <section className="hero pb-2 pt-36 m-0 z-20 relative">
      <h1
        style={{ right: "5px" }}
        className="section-padding-x hero-heading 2xl:mt-28 sm:w-max xl:text-9xl md:text-8xl sm:text-7xl md:mt-2 hero-heading text-shadow text-bold text-white relative text-7xl text-left w-min mt-10 z-50"
      >
        Hammaad Memon
      </h1>
      {/* <div className="bg-gradient-to-r from-black 2xl:h-105 2xl:-mt-36 lg:-mt-32 md:h-96 md:-mt-36 hero-banner slide-right shadow-2xl -mt-32 z-40 h-48 banner-shape " /> */}
      {/*2xl:px-32 xl:px-20 md:px-14 sm:px-12 px-6*/}
      <h2
        className="
        2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl hero-subheading text-shadow 
        font-black relative text-xl text-left w-max float-left mt-6  -mb-9 z-50 text-white tracking-wide
        section-padding-x
      "
      >
        Web Designer & Developer
      </h2>

      {/* <div className="bg-gradient-to-l from-black 2xl:h-24 2xl:w-auto 2xl:-mt-1 md:py-8 md:mt-3 alt-banner-shape shadow-2xl slide-left mt-4 h-14 z-40" /> */}
    </section>
  );
};

export default Hero;
