import React from "react";
import ScrollTarget from "./ScrollTarget";
import isLargeScreen from "./isLargeScreen";

const Certfications = () => {
  const certs = [
    "Software Development",
    "Database Administration",
    "HTML & CSS",
    "Javascript",
    "Python",
    "Java",
    "HTML5",
    "Azure AI",
    "Mobility & Devices",
    "Security",
  ];
  return (
    <section id="certifications" className="certifications-container section">
      <ScrollTarget name={"certifications"}></ScrollTarget>
      <h1 className="section-heading">Certifcations</h1>
      <p className="section-desc">MTA - Microsoft Technology Associate</p>
      <div className="w-min border-l-2" />
      <div className="lg:grid grid-cols-2 lg:gap-x-4 lg:gap-y-0">
        {certs.map((cert, i) => (
          <div
            key={i}
            className="translucent-horizontal lg:my-2 md:px-10 md:py-6 lg:py-4 alt-clipped-sm relative overflow-hidden z-10 w-full p-4 text-center rounded-sm my-2 flex items-center lg:gap-3"
          >
            <span className="skew-left pl-6 pr-10 bg-accent font-semibold text-white text-center absolute top-0 -left-2 w-max">
              MTA
            </span>
            {cert.length > 12 && !isLargeScreen() ? (
              <>
                <div className="lg:w-10 lg:h-10 dynamic self-start justify-self-start mx-3 w-8 h-8 m-auto">
                  <img
                    className="w-full h-full"
                    src="assets/microsoft.png"
                    alt="microsoft"
                  />
                </div>
                <h3 className="text-left content-heading my-1 md:my-0 tracking-wider text-gray-white">
                  {cert}
                </h3>
              </>
            ) : (
              <>
                <div className="lg:w-10 lg:h-10 dynamic self-start justify-self-start mx-3 w-8 h-8">
                  <img
                    className="w-full h-full"
                    src="assets/microsoft.png"
                    alt="microsoft"
                  />
                </div>
                <h3 className="content-heading my-1 md:my-0 tracking-wider text-white">
                  {cert}
                </h3>
              </>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Certfications;
