import React, { useState, useRef, useEffect } from "react";
import { HiOutlineMenuAlt2, HiX } from "react-icons/hi";
import { Link } from "react-scroll";
import { gsap } from "gsap";

import { CgMenuMotion } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";

const NavBar = ({ homeURL }) => {
  const links = ["projects", "skills", "certifications"];
  const [navOpen, setNavOpen] = useState(false);
  const animationContainer = useRef(null);
  useEffect(() => {
    if (!animationContainer) return;
    const q = gsap.utils.selector(animationContainer);
    gsap.from(q(".animate-slide"), {
      opacity: 0.01,
      duration: 0.35,
      stagger: 0.05,
      x: 120,
      ease: "power3.out",
    });
    gsap.from(q(".animate-fade"), {
      opacity: 0.01,
      duration: 0.3,
      stagger: 0.05,
      y: 20,
      ease: "power3.in",
    });
  });
  return (
    <nav className="z-50 absolute bg-transparent left-0 h-20 -mb-20 w-screen text-black text-sm lg:px-5 xl:px-6 py-20 px-0 pt-0">
      <div className="flex right-0 float-center w-full py-4 section-padding-x h-auto items-center	gap-3 ">
        <div className="hidden sm:flex float-left text-left gap-1.5 sm:gap-4 h-full items-center  ">
          {links.map((link, i) => (
            <Link
              to={link}
              spy={true}
              smooth={true}
              className={i === links.length - 1 ? "mr-auto" : ""}
            >
              <button
                // style={{ textShadow: "1px 1px #000000" }}
                className="lg:text-lg text-white text-center py-1"
              >
                <p className="content-text">
                  {link.substring(0, 1).toUpperCase() + link.substring(1)}
                </p>{" "}
              </button>
            </Link>
          ))}
        </div>

        <div
          ref={animationContainer}
          className="flex sm:hidden float-left text-left gap-1.5 sm:gap-4 h-full items-start flex-col "
        >
          <div
            className="animate-fade -ml-1"
            onClick={() => setNavOpen(!navOpen)}
          >
            {!navOpen && <HiOutlineMenuAlt2 color={"white"} size={28} />}
            {navOpen && <HiX color={"white"} size={28} />}
          </div>
          {navOpen &&
            links.map((link, i) => (
              <Link
                to={link}
                spy={true}
                smooth={true}
                className={i === links.length - 1 ? "mr-auto" : ""}
              >
                <button
                  // style={{ textShadow: "1px 1px #000000" }}
                  className="animate-slide lg:text-lg text-white text-center py-1"
                >
                  <p className="content-text">
                    {link.substring(0, 1).toUpperCase() + link.substring(1)}
                  </p>{" "}
                </button>
              </Link>
            ))}
        </div>

        <Link
          to={"contact"}
          spy={true}
          smooth={true}
          className="ml-auto self-start sm:self-center"
        >
          <button
            // style={{ textShadow: "1px 1px #000000" }}]
            className="z-50 transition duration-300 ease-in-out hover:shadow-lg hover:bg-white hover:text-black lg:text-lg md:px-7 md:py-2 text-white rounded-full bg-transparent border-2 border-white border-solid text-center px-5 py-1.5"
          >
            <p className="content-text w-full h-full font-bold tracking-wide">
              {"Get a Quote"}
            </p>{" "}
          </button>
        </Link>
      </div>
      {/* <div style={{ width: 50 }} className="glass slide-left"></div> */}
      {/* <div className="lg:hidden z-50 absolute top-7 right-2">
        <button onClick={() => toggleNavTray()}>
          {showNavTray ? (
            <AiOutlineClose
              color="black"
              size={35}
              className="dynamic z-50 top-8 float-right animate-in"
            ></AiOutlineClose>
          ) : (
            <CgMenuMotion
              className="md:w-20 md:h-20 dynamic z-50 float-right animate-in"
              color="black"
              size={55}
            ></CgMenuMotion>
          )}
        </button>
      </div> */}
    </nav>
  );
};

export default NavBar;
