import React, { useEffect, useState } from "react";
import { ReactP5Wrapper } from "react-p5-wrapper";
import waves from "./waves";

const Background = () => {
  return (
    <section
      style={{
        zIndex: -11,
      }}
      className="top-0 left-0 min-w-full min-h-screen bg-white hero-bg overflow-x-visible overflow-y-visible fixed"
    >
      <ReactP5Wrapper
        className="w-full h-screen"
        style={{ minHeight: "100vh" }}
        sketch={waves}
      />
    </section>
  );
};

export default Background;
