import React from "react";
import Tags from "./Tags";
import ScrollTarget from "./ScrollTarget";
import { AiFillDatabase } from "react-icons/ai";
import { RiCodeBoxFill } from "react-icons/ri";

const Skills = () => {
  const skillsList = [
    {
      name: "Frontend Development",
      description:
        "Projects customly handcrafted to ensure originality and unique user experiences.",
      languages: ["HTML", "CSS", "JavaScript"],
      frameworks: ["React", "Tailwind", "GSAP"],
      renderIcon: () => (
        <RiCodeBoxFill size={80} className="fg-accent m-auto" />
      ),
      wrap: false,
    },
    {
      name: "Backend Development",
      description:
        "Server and Database design that is efficient, capable and easily scalable.",
      languages: ["JavaScript", "SQL"],
      frameworks: ["Node.js", "Express"],
      renderIcon: () => {
        return <AiFillDatabase size={80} className="fg-accent m-auto w-full" />;
      },
      wrap: false,
    },
    {
      name: "Deep Learning Development",
      description:
        "Experience designing, training, and implementing deep learning algorithms to solve real world problems.",
      languages: ["JavaScript", "Python"],
      frameworks: ["Tensorflow.js", "Tensorflow", "Keras"],
      renderIcon: () => {
        return (
          <img
            src="assets/nn-light.png"
            alt="neural network"
            style={{ width: "160%" }}
            className="m-auto md:ml-5 lg:mr-0 lg:w-full"
          />
        );
      },
      wrap: true,
    },
  ];

  return (
    <section className="skills-container section">
      <ScrollTarget name={"skills"}></ScrollTarget>
      <h1 className="section-heading">Skills</h1>
      <p className="section-desc">
        {" "}
        Working on new and exciting projects means I'm always learning and
        expanding my skillset.
      </p>
      <div className="card-container">
        {skillsList.map((skill, i) => (
          <>
            <div className="card text-white" key={i}>
              <div className="sm:flex sm:items-center md:justify-center ">
                <div
                  className={
                    "m-auto w-24 sm:w-40 mb-6 sm:m-auto md:mr-4 lg:mr-8 lg:w-56 "
                  }
                >
                  {skill.renderIcon()}
                </div>
                <div className={skill.wrap ? "sm:ml-8 md:ml-6" : "lg:-ml-5"}>
                  <h3 className="content-heading sm:mb-1 md:my-2 mt-2 text-center sm:text-left tracking-wider">
                    {skill.name}
                  </h3>

                  <div className="lg:mt-0 lg:h-24 mt-3 px-4 sm:px-0">
                    <p className="content-text sm:mt-0 sm:text-left px-0 md:py-2 mb-2 text-center">
                      {skill.description}
                    </p>
                  </div>
                </div>
              </div>

              <div className="sm:flex lg:block xl:flex xl:max-w-full xl:mx-2 lg:float-bottom lg:bottom-0 lg:left-0 p-0 bg-transparent lg:mt-3">
                <div>
                  <p className="md:text-lg text-md text-left my-3">Languages</p>
                  <Tags list={skill.languages} name="languages" />
                </div>
                <div className="sm:mx-6 lg:mx-0 xl:mx-6">
                  <p className="md:text-lg text-md text-left my-3">
                    Frameworks
                  </p>
                  <Tags list={skill.frameworks} name="frameworks" />
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  );
};

export default Skills;
