import React from "react";

const Tags = ({ list, name = "" }) => {
  return (
    <div className="flex w-min">
      {list.map((item, i) => (
        <p
          key={name + i}
          className="mr-2 text-black w-min bg-white rounded-sm p-2 text-sm"
        >
          {item}
        </p>
      ))}
    </div>
  );
};

export default Tags;
