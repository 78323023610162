import React, { useState, useEffect } from "react";
import ScrollTarget from "./ScrollTarget";
import Popup from "reactjs-popup";
import emailjs from "emailjs-com";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [timeout, setTimeout] = useState(0);

  useEffect(() => {
    setInterval(() => {
      if (timeout > 0) {
        setTimeout(timeout - 1);
      }
    }, 60000);
  });

  const getFeedback = (isHeader) => {
    if (status === "success") {
      return isHeader
        ? "Message Sent"
        : "Thank you for reaching out, expect to hear back soon!";
    }
    if (status === "sending") {
      return isHeader ? "Sending" : "Your message is being sent...";
    }
    if (status === "failed") {
      return isHeader ? "Failed to Send" : "Unable to send your message";
    }
    if (status === "missing_data") {
      return isHeader ? "Missing Data" : "Please fill out all input fields.";
    }
    if (status === "time_out") {
      return isHeader
        ? "Timed Out"
        : "Please wait a minute before sending another message";
    }
  };

  const send = () => {
    setStatus("sending");
    if (timeout === 0) {
      const info = {
        name,
        email,
        message,
      };
      if (
        info.name.length > 0 &&
        info.email.length > 0 &&
        info.message.length > 0
      ) {
        emailjs
          .send(
            "service_1ivebnf",
            "template_w2he45c",
            info,
            "user_vI1qHPVjvfwv9xZvnJhms"
          )
          .then(() => {
            setStatus("success");
            setTimeout(1);
          })
          .catch(() => {
            setStatus("failed");
          });
      } else {
        setStatus(() => setStatus("missing_data"));
      }
    } else {
      setStatus(() => setStatus("time_out"));
    }
  };

  return (
    <>
      <section id="contact-container" className="contact-container section">
        <ScrollTarget name={"contact"}></ScrollTarget>
        <h1 className="section-heading">Contact</h1>
        {/* <div className="action-container w-full px-5 py-3 translucent rounded-lg grid grid-cols-1 grid-rows-2 md:-mt-3 lg:px-9 lg:py-4">
          <p className="section-desc w-full m-0 text-white">
            Need a custom website for your brand? Lets collaborate!
          </p>
          <a
            href="https://www.fiverr.com/hammaadmemon/develop-a-custom-sleek-and-responsive-website"
            target="_blank"
            rel="noreferrer"
            className="bg-transparent w-full m-auto text-center"
          >
            <button className="transition duration-150 ease-in-out md:text-xl mt-2 hover:bg-transparent border-2 border-white border-solid hover:text-white text-black bg-white action-button rounded-full -bottom-28 left-0 px-8 py-2 font-bold">
              Get Started
            </button>
          </a>
        </div> */}
        <p className="section-desc mt-4">
          Looking to take your brand to the next level with a custom-designed
          web presence? Send me a message below:
        </p>
        <input
          className="contact-info"
          type="text"
          name="name"
          id="name-input"
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <input
          className="contact-info"
          type="email"
          name="email"
          id="email-input"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <textarea
          className="contact-info h-28"
          type="textarea"
          id="message-input"
          name="message"
          placeholder="Message"
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <br />

        <Popup
          trigger={
            <div>
              <button className="btn-primary" onClick={() => send()}>
                Submit
              </button>
            </div>
          }
          position="right center"
          modal
          nested
        >
          {(close) => (
            <div className="modal px-5 rounded-lg w-64 m-auto py-3 fogged-glass">
              <div className="header">
                <p>{getFeedback(true)}</p>{" "}
              </div>
              <div className="content">
                <p>{getFeedback(false)}</p>
              </div>
              <div className="actions">
                <button
                  className="btn-primary border-black text-black"
                  onClick={() => {
                    close();
                  }}
                >
                  Dismiss
                </button>
              </div>
            </div>
          )}
        </Popup>
      </section>
    </>
  );
};

export default Contact;
